<template>
    <div
        id="files"
        class="tab-content"
    >
        <attachments
            key="file"
            type="main"
            :attachments-type="'main'"
            :files="order.files_main"
            :order-status="order.sw_status"
            :sw-id="swId"
            loading-url="/api/file/main/upload"
            delete-url="/api/file/main/delete"
            @onFilesDelete="onFilesDelete($event, 'main')"
            @uploadedFiles="onUploadedFiles($event, 'main')"
        />
    </div>
</template>

<script>
import Attachments from '@/components/account/orders/Attachments.vue'

export default {
    components: {
        Attachments
    },
    props: {
        order: {
            type: Object
        },
        swId: {
            type: Number
        }
    },
    methods: {
        onFilesDelete(file_id) {
            this.order.counters.files_main_count -= 1
            this.order.files_main = this.order.files_main.filter((item) => item.file_id !== file_id)
        },
        onUploadedFiles(files) {
            this.order.files_main.push(files.file)
            this.order = { ...files.order }
            this.order.counters.files_main_count += 1
        }
    }
}
</script>
